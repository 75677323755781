.backdrop-cover {
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 20;

  img {
    width: 100%;
    height: 540px;
    object-fit: contain;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
