.mobileslide-drawer {
  .MuiDrawer-paperAnchorDockedRight {
    border-left: 0;
  }

  .mobileslide-header {
    padding: 5% 8%;
    display: flex;
    justify-content: space-between;
    background-color: #172b4d;

    .mobileslide-logo {
      .mobileslide-png {
        height: 40px;
        object-fit: contain;
        filter: invert(100%);
      }
    }

    .mobileslide-cross {
      svg {
        color: #ffffff;
      }
    }
  }

  .mobileslide-body {
    background-color: #172b4d;
    padding: 0% 8%;
    height: 100%;

    .mobileslide-section {
      display: flex;
      justify-content: center;
      padding: 4% 5%;

      a {
        text-transform: uppercase;
        font-size: 20px;
        color: white;
        font-family: "Montserrat-Bold";
        transition: transform 0.5s ease-in-out;
        text-decoration: none;

        &:hover {
          color: #d0cab5;
          transform: scale(1.3) !important;
        }

        &.active {
          font-weight: bold;
          color: #d0cab5;
        }
      }
    }

    .mobileslide-actions {
      position: absolute;
      bottom: 0;
      padding-bottom: 5%;
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .login {
        text-transform: uppercase;
        font-size: 20px;
        color: white;
        font-family: "Montserrat-Bold";
        text-decoration: none;
        transition: transform 0.5s ease-in-out;

        &:hover {
          color: #d0cab5;
          transform: scale(1.3) !important;
        }
      }

      .signup {
        text-transform: uppercase;
        font-size: 20px;
        color: white;
        font-family: "Montserrat-Bold";
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid white;
        border-radius: 5px;
      }
    }
  }
}
