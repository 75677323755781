@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 578px) {
  .footer-container {
    padding: 5px;

    .footer-container .footer-link {
      color: white;
      margin-bottom: 20px;
      display: block;
    }
  }
}
