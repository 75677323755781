.footer-container {
  background-color: black;
  height: 800px;
  margin: 0 0 0 0;

  .title {
    font-family: "Montserrat-Light";
  }
  .footer-logo {
    // width: 100px;
    height: 4vw;
    margin-bottom: 8px;
  }
  .logo {
    width: 40px;
    height: 40px;
  }
  & a {
    color: white;
  }
  & a:hover {
    text-decoration: none;
  }

  .footer-link {
    &:hover {
      color: #2b93c9;
    }
  }
}

@media (max-width: 768px) {
  .footer-logo {
    height: 50px !important;
  }
}
