body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > #root > div {
  height:100%;
  font-family: 'Montserrat-Regular', cursive;
  
}


@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat-Black"), url(./fonts/Montserrat/Montserrat-Black.otf) format("truetype");
}


@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Regular"), url(./fonts/Montserrat/Montserrat-Bold_0.otf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-Regular"), url(./fonts/Montserrat/Montserrat-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"), url(./fonts/Montserrat/Montserrat-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"), url(./fonts/Montserrat/Montserrat-Light.otf) format("truetype");
}
