.backtotop {
  .ant-back-top {
    right: 50px;
  }
  .backtotop-cover {
    .backtotop-archor {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #2b93c9;
      border-radius: 10px;
      width: 50px;

      svg {
        font-size: 50px;
        color: white;
      }
    }
  }
}
