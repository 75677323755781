#root {
  background: rgb(250, 251, 254);
  background: linear-gradient(
    180deg,
    rgba(250, 251, 254, 1) 0%,
    rgba(255, 255, 255, 1) 45%
  );
}

.outer-container {
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
}

@media only screen and (min-width: 1600px) {
  .outer-container {
    margin-right: 15%;
    margin-left: 15%;
    margin-top: 5%;
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (max-width: 992px) {
  .outer-container {
    margin-right: 5%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 768px) {
  .outer-container {
    margin-right: 5%;
    margin-left: 5%;
  }
}

@media only screen and (max-width: 578px) {
  .outer-container {
    margin-right: 5%;
    margin-left: 5%;
  }
}
