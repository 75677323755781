.cny_banner {
  width: 100%;
}

.test-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: auto !important;

  .navbar-trans {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #6b6b6b;
    background: #ffffff;
    box-shadow: inset 0 -1px 0 0 #f0f1f1;
    transition: background-color 200ms linear;

    .otma-logo {
      height: 40px;
      object-fit: contain;  
      filter: invert(3%) sepia(87%) saturate(5752%) hue-rotate(240deg) brightness(78%) contrast(101%);

      //match with nav bar color 
      // filter: invert(43%) sepia(95%) saturate(427%) hue-rotate(155deg) brightness(97%) contrast(82%);
      // filter: invert(100%) sepia(77%) saturate(1440%) hue-rotate(177deg) brightness(99%) contrast(89%);
    }

    .otma-title {
      font-family: "Montserrat-Bold";
      font-size: 45px;
    }

    .objective {
      padding-top: 15px;
      font-size: 24px;
      font-family: "Montserrat-SemiBold";
      letter-spacing: 1px;
    }

    .mobile-slidepane-trigger {
      display: none;
      svg {
        // color: #2b93c9 !important;
        color: #050A30 !important;
        font-size: 40px;
      }
    }

    .navbar-expanded {
      width: 100%;

      .nav_text {
        font-family: "Montserrat-SemiBold";
        margin-left: 5px;
        margin-right: 20px;
        align-self: center;
        text-align: center;
        color: #000 !important;
        font-size: 14px;
        text-decoration: none;

        &.active {
          // color: #2b93c9 !important;
          color: #050A30 !important;
          font-weight: bold;
        }

        &:hover {
          // color: #2b93c9 !important;
          color: #050A30 !important;
        }
      }

      .otma-actions {
        display: flex;
        align-items: center;
        margin-left: auto;

        .apply-button {
          padding: 8px 20px 8px 20px;
          font-size: 14px;
          font-weight: bold;

          &.register {
            margin-right: 10px;
            border: 1px solid #000;
            background-color: transparent;
            color: #000;
          }

          &.login {
            // background-color: #2b93c9;
            background-color: #050A30;
          }
        }
      }
    }
  }
}

@media only screen and( max-width: 1366px) {
  .navbar-trans {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .nav_text {
    align-self: center;
    text-align: right;
  }
}
@media only screen and( max-width: 1024px) {
  .test-container {
    .apply-button {
      padding: 12px 10px 12px 10px !important;
      width: 200px;
    }
    .btn-primary {
      // background-color: transparent !important;
      // border-color: transparent !important;
    }
  }
}
