@media only screen and (max-width: 1300px) {
  .navbar-trans .navbar-expanded .nav_text {
    margin-right: 13px !important;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .navbar-trans {
    .mobile-slidepane-trigger {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 578px) {
}
