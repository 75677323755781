@media only screen and (min-width: 1600px) {
}

@media only screen and (max-width: 1300px) {
  .mobileslide-body {
    .mobileslide-section {
      padding: 3% 5% !important;
    }
  }
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
  .mobileslide-body {
    .mobileslide-section {
      padding: 5% 5% !important;
    }
  }
}

@media only screen and (max-width: 578px) {
  .mobileslide-body {
    .mobileslide-section {
      padding: 4% 5% !important;
    }
  }
}
